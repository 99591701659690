import React, {Component} from 'react';
import RH from './Initial/images/RECURSOS_HUMANOS.png';
import Soporte from './Initial/images/SOPORTE.png';


const viewAgencies = () => {

  var navegador = localStorage.getItem("navegador");
  if (navegador === "Internet Explorer") {
    document.body.scrollTop = 1000000;
    document.documentElement.scrollTop = 1000000;
  } else {
    document.body.scrollTop = 10000000000000;
    document.documentElement.scrollTop = 10000000000000;
  }
  var li = document.getElementById('age-row');
  var lii = document.getElementById('age-row2');
  var li2 = document.getElementById('site-row');
  li2.className = "hide";
  var lii2 = document.getElementById('site-row2');
  lii2.className = "hide";

  if (li.className === "") {
    li.className = "hide";
    lii.className = "hide";
  } else {
    li.className = "";
    lii.className = "";
  }

  if (navegador === "Internet Explorer") {
    document.body.scrollTop = 1000000;
    document.documentElement.scrollTop = 1000000;
  } else {
    document.body.scrollTop = 10000000000000;
    document.documentElement.scrollTop = 10000000000000;
  }
}

const viewSites = () => {

  var navegador = localStorage.getItem("navegador");
  if (navegador === "Internet Explorer") {
    document.body.scrollTop = 1000000;
    document.documentElement.scrollTop = 1000000;
  } else {
    document.body.scrollTop = 10000000000000;
    document.documentElement.scrollTop = 10000000000000;
  }

  var li2 = document.getElementById('age-row');
  li2.className = "hide";
  var li = document.getElementById('site-row');
  var lii = document.getElementById('site-row2');
  var lii2 = document.getElementById('age-row2');
  lii2.className = "hide";

  if (li.className === "") {
    li.className = "hide";
    lii.className = "hide";
  } else {
    li.className = "";
    lii.className = "";
  }

  if (navegador === "Internet Explorer") {
    document.body.scrollTop = 1000000;
    document.documentElement.scrollTop = 1000000;
  } else {
    document.body.scrollTop = 10000000000000;
    document.documentElement.scrollTop = 10000000000000;
  }
}

class Footer extends Component {


  setFooter = () => {  
    var Contacts = "CONTACTOS";
    var SiteMap = "MAPA DEL SITIO";
    var HUMANRESOURCES = "RECURSOS HUMANOS";
    var TECHNICALSUPPORT = "SOPORTE TÉCNICO";
    var Caras = "NOSOTROS";
    var Organigrama = "Organigrama"
    var carasNuevas = "Los que llegan";
    var Ascensos = "Los que crecen";
    var Cumpleanios = "Los que cumplen";
    var Camiseta = "Aniversarios";
    var Conoce = "Conoce mejor a";
    var Vacantes ="Vacantes";
    var Tmt = "Requisición de Talento";
    var Enterate = "ENTÉRATE";
    var Festividades = "Algo para celebrar";
    var corcho = "El corcho"
    var Salud = "Tu salud";
    var Proveedores = "Otras cosas buenas";
    var Biblioteca = "Sala de Lectura";
    var Herramientas = "WORK APPS";
    var legales = "POLÍTICAS";
    var Politicas = "Políticas globales";
    var Formatos = "Políticas y formatos locales";
    var Red = "RED MCCANN";
    var Latam = "INSIDE LATAM";
    var wg= "INSIDE WG";
    var ipg="INSIDE INTERPUBLIC";
    var b2n = "B2N";
    var bienestar= "Cultura y Bienestar";
    var finanzas = "Finanzas"
      return (
        <div className="container"  style={{paddingLeft:'80px', paddingRight:'80px'}}>
            <div className="row">
              <div id="FootFont" className="col-md-12 no-padding footer-font">
                <hr id="hr-foot" className="hrb" />
                <div className="row">
                  <div className="col-md-6 col-sm-12 sitesFooter" align="center">
                    <h5 id="contactos-pie" onClick={(e) => { viewAgencies() }} className="cursor-pointer h6-Footer link-footer-1">{Contacts}</h5>
                    <div id="age-row2" className="hide">
                      <hr className="hrb2" />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 sitesFooter" align="center">
                    <h5 id="sitemap-pie" onClick={(e) => { viewSites() }} className="cursor-pointer h6-Footer link-footer-1">{SiteMap}</h5>
                    <div id="site-row2" className="hide">
                      <hr className="hrb2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid row hide" id="age-row">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6" >
                  <div className="rh-ti">
                   <img src={RH} />
                  </div>  
                  <div>
                    <h6 id="hum-foot" className="h6-Footer">{HUMANRESOURCES}</h6>
                    <span className="no-white">Aurea Pineda</span>
                    <br />
                    <span className="no-white"><a className="aBlue" href="mailto:Aurea.Pineda@mccann.com.mx">Aurea.Pineda@mccann.com.mx</a></span>
                  </div>  
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6" >
                  <div className="rh-ti">
                    <img src={Soporte} />
                  </div> 
                  <div>
                    <h6 id="tec-foot" className="h6-Footer">{TECHNICALSUPPORT}</h6>
                    <span className="no-white">Victor Romero</span>
                    <br />
                    <span className="no-white"><a className="aBlue" href="Victor.Romero@mccann.com.mx">Victor.Romero@mccann.com.mx</a></span>
                    </div>
                </div>
              </div>
            </div>
  
            <div className="container-fluid hide " id="site-row">
              <div className="row centrar-pie">
                <div className="col-sm-4 col-md-2 col-lg-2" >
                    <h6 className="h6-Footer">{Caras}</h6>
                    <span><a href="/organigrama" className="no-white">{Organigrama}</a></span>
                    <br />
                    <span><a href="/losquellegan" className="no-white">{carasNuevas}</a></span>
                    <br />
                    <span><a href="/losquecrecen" className="no-white">{Ascensos}</a></span>
                    <br />
                    <span><a href="/losquecumplen" className="no-white">{Cumpleanios}</a></span>
                    <br />
                    <span><a href="/algoparacelebrar" className="no-white">{Festividades}</a></span>
                    <br/>
                    <span><a href="/conocemejora" className="no-white">{Conoce}</a></span>
                    <br />
                    <span><a href="/aniversarios" className="no-white">{Camiseta}</a></span>
                </div>
                <div className="col-sm-4 col-md-2 col-lg-2" >
                    <h6 className="h6-Footer">{Enterate}</h6>
                    <span><a href="/b2n" className="no-white">{b2n}</a></span>
                    <br />
                    <span><a href="/noticias" className="no-white">Noticias</a></span>
                    <br />
                    <span><a href="/cultura-bienestar" className="no-white">{bienestar}</a></span>
                    <br />
                    <span><a href="/vacantes" className="no-white">{Vacantes}</a></span>
                    <br />
                    <span><a href="/tu-salud" className="no-white">{Salud}</a></span>
                    <br />
                    <span><a href="/otras-cosas-buenas" className="no-white">{Proveedores}</a></span>
                    <br />
                    <span><a href="/sala-de-lectura" className="no-white">{Biblioteca}</a></span>
                    <br/>
                    <span><a href="/el-corcho" className="no-white">{corcho}</a></span>
                </div>
                <div className="col-sm-4 col-md-2 col-lg-2" >
                    <h6 id="tec-foot" className="h6-Footer">{Herramientas}</h6>
                </div>
              <div className="col-sm-4 col-md-3 col-lg-3" >
                    <h6 className="h6-Footer">{legales}</h6>
                    <span><a href="https://inside.interpublic.com/agencyresources/standardpolicies" target="blank" className="no-white">{Politicas}</a></span>
                    <br />
                    <span><a href="/politicas-y-formatos" className="no-white">{Formatos}</a></span>
                    <br />
                    <span><a href="/finanzas" className="no-white">{finanzas}</a></span>
                    <br/>
                    <span><a href="https://tmt.mccann.com.mx/" target="_blank" className="no-white">{Tmt}</a></span>
                    <br/>
                    <span><a href="https://inside.interpublic.com/login/" className="no-white" target="_blank">Alert Line</a></span>
                </div>
                <div className="col-sm-4 col-md-3 col-lg-3" >
                    <h6 className="h6-Footer">{Red}</h6>
                    <span><a href="https://intranet-latam.mccann.com/" target="blank" className="no-white">{Latam}</a></span>
                    <br />
                    <span><a href="https://inside.mccannwg.com/" target="blank" className="no-white">{wg}</a></span>
                    <br />
                    <span><a href="https://inside.interpublic.com/" target="blank" className="no-white">{ipg}</a></span>
                </div>
              </div>
            </div>
            <br/>
           
      </div> 
    )
  }


  setFooterSocial = () => {
    return(
      <div className="center visibility">
      {/* <div className="topnav2 foo-mm">
        <div className="red-ss"><a href="https://mi-mercadito.mccann.com.mx/" className="entypo-mercadito btns-footer" target="_blank">mercadito</a></div>
      </div>  */}
      <div className="topnav2 foo-ki">
        <div className="red-ss"><a href="https://azrlmwgapp001.la.corp.ipgnetwork.com:92/" className="entypo-kiosko btns-footer" target="_blank">kiosko</a></div>
      </div>
      <div className="topnav2 foo-tog">
        <div className="red-ss"><a href="https://together.interpublic.com/" className="entypo-together btns-footer" target="_blank">together</a></div>
      </div>
      
      <div className="topnav2 foo-ct">
        <div className="red-ss"><a href="mailto:RHMcCann@mccann.com.mx" className="entypo-contactohome btns-footer">contacto</a></div>
      </div>
      <div className="topnav2 foo-cal cursor-pointer">
        <div className="red-ss"><a data-toggle="modal" data-target="#ModalLong" className="entypo-calendariohome btns-footer">calendario</a></div>
      </div>
      <div className="topnav2 foo-fb">
        <div className="red-ss"><a href="https://www.facebook.com/MccannWorldgroupMX" className="facebook  btns-footer"  target="_blank">FB</a></div>
      </div>
      <div className="topnav2 foo-tw">
        <div className="red-ss"><a href="https://twitter.com/mccannmexico" className="twitter  btns-footer" target="_blank">TW</a></div>
      </div>
      <div className="topnav2 foo-insta">
        <div className="red-ss"><a href="https://www.instagram.com/mccannmexico/" className="twitter  btns-footer" target="_blank">TW</a></div>
      </div>
    </div>
    )
  }

  


  render() {

   








    return (
        <footer>
            <div className="container-fluid border-foo">
              {this.setFooter()}
            </div>
            <div className="container-fluid bg-footer-social">
              {this.setFooterSocial()}
            </div> 

          {/* MODAL CALENDARIO*/}
          <div className="modal fade bd-example-modal-lg"  tabIndex="-1" aria-labelledby="exampleModalLongTitle" aria-hidden="true" id="ModalLong">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLongTitle">Calenadario 2025</h1>
                   <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div align="center">
                    <img src="https://mccannet.mccann.com.mx/wp-content/uploads/2025/01/Calendario-Fechas-RH-2025-e1736964554366.png" className="image-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </footer>
    );
    }
}
export default Footer;